import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function Oskar() {
  return (
    <div class="container">
      <div class="jumbotron text-center">Oskar är 29 år gammal.</div>
    </div>
  );
}

export default Oskar;
